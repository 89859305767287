<template>
  <div>
        <div class="desk" :style="`background-color:${bgColor}`">
            <div class="h2" v-show="deskShow">{{title}}</div>
            <div class="content" v-show="deskShow">如需体验案例，或查看更多功能欢迎点击导航联系我们</div>
            <div class="slidShow" v-show="deskShow">
                <div class="arrows left" @click="arrowClick('left')">
                </div>
                <el-carousel indicator-position="none" :interval="5000"   arrow="never"  ref="backShow">
                    <el-carousel-item v-for="(item,index) in list" :key="index">
                    <img :src="item" alt="">
                    </el-carousel-item>
                </el-carousel>
                <div class="arrows right" @click="arrowClick('right')">
                </div>
            </div>
        </div>
  </div>
</template>

<script>
export default {
    props:{
        title:{
            type:String,
            default:''
        },
        list:{
            type:Array,
            default:()=>{}
        },
        bgColor:{
            type:String,
            default:''
        },
        deskShow:{
            type:Boolean,
            default:false
        }
},
    data(){
        return{
            
        }
    },
    methods:{        
        arrowClick(val) {
            let timer = true
            if(val === 'right') {
                timer = true
                if(!timer) return
                timer = false
                setTimeout(()=>{
                    this.$refs.backShow.next() 
                },50)
            } else {
                timer = true
                if(!timer) return
                timer = false
                setTimeout(()=>{
                    this.$refs.backShow.prev() 
                },50)
            }       
        }
    }
}
</script>

<style lang="less" scoped>
        .desk{
            height: 983px;
            background-color: #f8f8f8;
            .h2{
                padding: 80px 0 40px;
            }
            .content{
                margin-bottom: 50px;
            }
            .slidShow{
                background: url('~@/assets/image/enterprise/icon(17).png') no-repeat;
                width: 900px;
                height: 652px;
                margin: 0 auto;
                position: relative;
                .arrows{
                    position: absolute;
                    width: 65px;
                    height: 65px;
                    border-radius: 50%;
                    border: 3px solid #9f9f9f;
                    transform: translateY(-130%);
                    cursor: pointer;
                    outline: none;
                }
                .left{
                    top: 50%;
                    left: -152px;
                    background: url('~@/assets/image/enterprise/left.png') no-repeat 12px 13px;
                    background-size: 50% 50%;
                    &:hover{
                        background: #078afa url('~@/assets/image/enterprise/left_hover.png') no-repeat 12px 13px;
                        border: 3px solid #078afa;
                        background-size: 50% 50%;

                    }
                } 
                .right{
                    top: 50%;
                    right: -152px;
                    background: url('~@/assets/image/enterprise/right.png') no-repeat 16px 14px;
                    background-size: 50% 50%;
                    &:hover{
                        background: #078afa url('~@/assets/image/enterprise/right_hover.png') no-repeat 16px 14px;
                        border: 3px solid #078afa;
                        background-size: 50% 50%;
                    }
                }
                img{
                    width: 100%;
                    height: 100%;
                }
            }
        }
            .el-carousel--horizontal{
                width: 824px;
                height: 473px;
                overflow: hidden;
            
            }
            .el-carousel{
                top: 37px;
                left: 38px;
            }
            ::v-deep .el-carousel__container{
                width: 824px !important;
                height: 473px !important;
            }
@media screen and (max-width: 1024px) {
        .desk{
            height: 320px;
            .h2{
                padding: 30px 0 12px 0;
            }
            .content{
                margin-bottom: 20px;
            }
            .slidShow{
                width: 255px;
                height: 185px;
                background-size: 255px 185px;
                .arrows{
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    border: 3px solid #9f9f9f;
                    transform: translateY(-130%);
                    cursor: pointer;
                }
                .left{
                    top: 50%;
                    left: -40px;
                    background: #078afa url('~@/assets/image/enterprise/left_hover.png') no-repeat 4px 5px;
                    border: 3px solid #078afa;
                    background-size: 50% 50%;
                    &:hover{
                        background: #078afa url('~@/assets/image/enterprise/left_hover.png') no-repeat 4px 5px;
                        border: 3px solid #078afa;
                        background-size: 50% 50%;
                    }

                } 
                .right{
                    top: 50%;
                    right: -40px;
                    background: #078afa url('~@/assets/image/enterprise/right_hover.png') no-repeat 5px 5px;
                    border: 3px solid #078afa;
                    background-size: 50% 50%;
                    &:hover{
                        background: #078afa url('~@/assets/image/enterprise/right_hover.png') no-repeat 5px 5px;
                        border: 3px solid #078afa;
                        background-size: 50% 50%;
                    }
                } 
            }
        }
        .el-carousel--horizontal{
                width: 233px;
                height: 132px;
                overflow: hidden;
            
        }
        .el-carousel{
            top: 10px;
            left: 11px;
        }
        ::v-deep .el-carousel__container{
            width: 233px !important;
            height: 132px !important;
        }
    }
</style>