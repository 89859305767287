<template>
    <div>
        <div class="user">
            <div class="h2" v-show="userShow">用户端截图预览</div>
            <div class="content" v-show="userShow">如需体验案例，或查看更多功能欢迎点击导航联系我们</div>
                <div class="slide" v-show="userShow">
                    <div class="user-swiper-container"  @mouseenter="user_enter" @mouseleave="user_leave">
                        <div class="swiper-button-prev swiper-button-prev1 arrows left"></div>
                        <swiper ref="userSwiper" :options="userOptions">
                            <swiper-slide v-for="(item,index) in list" :key="index">
                                <img :src="item" alt />
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-button-next swiper-button-next1 arrows right">
                    </div>
                </div>
                </div>
        </div>
    </div>
</template>

<script>
import 'swiper/css/swiper.css'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

export default {
    props:{
        list:{
            type:Array,
            default:()=>{}
        },
        userShow:{
            type:Boolean,
            default:false
        }
    },
    components:{Swiper,SwiperSlide},
    data(){
        return{
            userOptions: {
                pagination: {
                el: ".user-swiper-pagination"
                },
                loop: true,
                effect: 'coverflow',
                slidesPerView: 2,
                centeredSlides: true,
                initialSlide: 1,
                coverflowEffect: {
                    rotate: 0,
                    stretch:2,
                    depth: 200,
                    modifier: 2,
                    slideShadows: false
                },
                speed: 1000,
                //左右箭头按钮
                navigation: {
                    prevEl: ".swiper-button-prev1",
                    nextEl: ".swiper-button-next1"
                },
                //不需要自动轮播时
                // autoplay: false,
                // 设置自动轮播的延迟时间
                autoplay: {
                    delay: 1000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                paginationAsRange : true,
                
                loopAdditionalSlides : 1,
                loopedSlides :6,
                

            },
        }
    },
    mounted(){
        this.userSwiper.slideTo(1, 1000, false);
    },
     /* 引入组件 */
    computed: {
        userSwiper() {
            return this.$refs.userSwiper.$swiper;
        }
    },
    methods:{        
        //通过获得的swiper对象来暂停自动播放
            user_enter() {
                this.userSwiper.autoplay.stop()
            },
            user_leave() {
                this.userSwiper.autoplay.start()
            }
    }
}
</script>

<style lang="less" scoped>
    .user{
        height: 912px;
        background-color: #f8f8f8;
        .h2{
            padding: 80px 0 40px;
        }
        .content{
            margin-bottom: 50px;
        }
        .slide{
            width: 840px;    
            margin: 0 auto;
            position: relative;
            .user-swiper-container{
                width: 100%;
            }
            .arrows{
                position: absolute;
                width: 65px;
                height: 65px;
                border-radius: 50%;
                border: 3px solid #9f9f9f;
                transform: translateY(-130%);
                cursor: pointer;
                outline: none;
            }
            .left{
                top: 63%;
                left: -120px;
                background: url('~@/assets/image/enterprise/left.png') no-repeat 12px 13px;
                background-size: 50% 50%;
                &:hover{
                    background: #078afa url('~@/assets/image/enterprise/left_hover.png') no-repeat 12px 13px;
                    border: 3px solid #078afa;
                    background-size: 50% 50%;
                }
            } 
            .right{
                top: 63%;
                right: -120px;
                background: url('~@/assets/image/enterprise/right.png') no-repeat 16px 14px;
                background-size: 50% 50%;
                &:hover{
                    background: #078afa url('~@/assets/image/enterprise/right_hover.png') no-repeat 16px 14px;
                    border: 3px solid #078afa;
                    background-size: 50% 50%;
                }
            }
        }
    }
    .swiper-button-prev::after{
        content: "" !important;
    }
    .swiper-button-next::after{
        content: "" !important;
    }
@media screen and (max-width: 1024px) {
    .user{
        width: 100%;
        height: 315px;
        .h2{
            line-height: inherit;
            padding: 25px 0 8px;
        }
        .content{
            margin-bottom: 15px;
        }
        .slide{
            width: 256px;    
            .user-swiper-container{
                img{
                    width: 108px;
                    height: 208px;
                }
            }
                .arrows{
                position: absolute;
                width: 25px;
                height: 25px;
                border-radius: 50%;
                border: 3px solid #9f9f9f;
                transform: translateY(-130%);
                cursor: pointer;
            }
            .left{
                top: 72%;
                left: -38px;
                background: #078afa url('~@/assets/image/enterprise/left_hover.png') no-repeat 4px 5px;
                border: 3px solid #078afa;
                background-size: 50% 50%;
                &:hover{
                    background: #078afa url('~@/assets/image/enterprise/left_hover.png') no-repeat 4px 5px;
                    background-size: 50% 50%;
                }
            } 
            .right{
                top: 72%;
                right: -38px;
                background: #078afa url('~@/assets/image/enterprise/right_hover.png') no-repeat 5px 5px;
                border: 3px solid #078afa;
                background-size: 50% 50%;
                &:hover{
                    background: #078afa url('~@/assets/image/enterprise/right_hover.png') no-repeat 5px 5px;
                    background-size: 50% 50%;
                }
            }
        }
    }
}

</style>